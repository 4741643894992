<template>
  <div id="app">
    <router-view />
    
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  components: {
    
  },
}
</script>

<style lang="scss">

body {
  margin: 0;
  padding: 0;
  font-family: "微软雅黑", "Microsoft YaHei",sans-serif;
  font-size: 14px;
  background-color: #fff;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
a {
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
  font-size: 16px;
  color: #000000;
}
</style>
