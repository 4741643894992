import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    // redirect: '/homePage',
    component: () =>
        import ('@/views/Index/index.vue'),
    children: [{
            path: '/',
            name: 'homePage',
            component: () =>
                import ('@/views/homePage/index.vue'),
            meta: {
                title: '网站首页'
            }
        },
        //搜索页
        {
            path: '/search/:title',
            name: 'search',
            component: () =>
                import ('@/views/search/index.vue'),
            meta: {
                title: '搜索页'
            }
        },
        {
            path: '/groupProfile/organization',
            name: 'groupOrganization',
            component: () =>
                import ('@/views/groupProfile/components/organization/index.vue'),
            meta: {
                title: '组织机构'
            }
        },
        //集团概况
        {
            path: '/groupProfile/:id',
            name: 'groupProfile',
            component: () =>
                import ('@/views/groupProfile/index.vue'),
            meta: {
                title: '集团概况'
            }
        },
        //新闻中心
        {
            path: '/pressCenter/:id',
            name: 'pressCenter',
            component: () =>
                import ('@/views/pressCenter/index.vue'),
            meta: {
                title: '新闻中心'
            }
        },
        {
            path: '/pressCenter/radioReport/:id',
            name: 'radioReport',
            component: () =>
                import ('@/views/pressCenter/components/radioReport/index.vue'),
            meta: {
                title: '视频报道'
            }
        },

        //业务板块 businessUnit
        {
            path: '/businessUnit/:id',
            name: 'businessUnit',
            component: () =>
                import ('@/views/businessUnit/index.vue'),
            meta: {
                title: '新闻中心'
            }
        },

        //业务板块/科技研发
        {
            path: '/businessUnit/technology/:id',
            name: 'technology',
            component: () =>
                import ('@/views/technology/index.vue'),
            meta: {
                title: '科技研发'
            }
        },

        //党的建设
        {
            path: '/partyBuilding/:id',
            name: 'partyBuilding',
            component: () =>
                import ('@/views/partyBuilding/index.vue'),
            meta: {
                title: '党的建设'
            }
        },
        //文化频道
        // {
        //   path: '/culture/:id',
        //   name: 'culture',
        //   component: () => import('@/views/culture/index.vue'),
        //   meta: {
        //     title: '文化频道'
        //   }
        // },
        //信息公开
        {
            path: '/information/:id',
            name: 'information',
            component: () =>
                import ('@/views/information/index.vue'),
            meta: {
                title: '信息公开'
            }
        },
        //甘肃建投报
        {
            path: '/newsPaper/:id',
            name: 'newsPaper',
            component: () =>
                import ('@/views/newsPaper/index.vue')
        },
        //联系我们
        {
            path: '/contactUs/:id',
            name: 'contactUs',
            component: () =>
                import ('@/views/contactUs/index.vue')
        },
        //文章详情
        {
            path: '/detail/:parentId/:id',
            name: 'detail',
            component: () =>
                import ('@/views/detail/index.vue')
        },
        // 学党史
        {
            path: '/learn/:id',
            name: 'learn',
            component: () =>
                import ('@/views/learn/index.vue')
        },
        // 假冒国企公告专栏
        {
          path: '/notice/:id',
          name: 'notice',
          component: () =>
              import ('@/views/notice/index.vue')
        },
        // 纪检举报
        {
            path: '/report',
            name: 'report',
            component: () =>
                import ('@/views/report/index.vue'),
            meta: {
                title: '纪检举报'
            },
            children: []
        },
        // 信访中心
        {
            path: '/petition',
            name: 'petition',
            component: () =>
                import ('@/views/petition/index.vue'),
            meta: {
                title: '信访平台'
            },
            children: []
        }
    ]
}]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router